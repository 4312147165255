import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import Layout from "../../components/layout"
import AboutComponent from "../../components/pages/main/about"
import BannerComponent from "../../components/pages/features/banner"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeaturesComponent from "../../components/pages/main/landing-features"
import FeaturesGridComponent from "../../components/pages/features/grid"
import FeatureAccordion from "../../components/pages/main/accordion"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs'

const subscriptionsAccordionData = [
  {
    name: "templates",
    title: "Что такое шаблоны абонементов в Параплан CRM?",
    content: `
      <p>Шаблоны - это ускоритель оформления абонементов. Владельцу детского образовательного центра нужно только подготовить тарифную сетку и один раз заполнить условия по абонементам, то есть создать шаблоны. При следующем оформлении абонемента по шаблону данные автоматически подставляются системой. Наименование занятий и их количество, период, стоимость, срок действия - администратору не нужно каждый раз вносить эту информацию. CRM-система все сделает сама.</p>
    `,
  },
  {
    name: "reminder",
    title: "Как напомнить клиенту, что его абонемент скоро закончится?",
    content: `
      <p>В Параплан CRM есть инструмент для удержания клиентов - автоуведомления по SMS и Email. Уведомления отправляются автоматически по различным триггерам (событиям) в системе. В том числе есть предупреждение о скором окончании абонемента за 1, 3, 7 дней или вы можете отправить уведомление в день завершения действия абонемента. Кстати, такие же уведомления будут приходить на Email ответственным сотрудникам.</p>
    `,
  }
]

const ClientsFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Учет абонементов', url: ''}
  ];
  return (
    <Layout headerColor={"#03C4A4"} page={"subscriptions"}>
      <SEO
        title="Абонементы | CRM-система учета для детских центров"
        keywords={[`учет абонементов`, `продажи абонементов`, `список абонементов`]}
        description="Облачная CRM Параплан автоматизирует создание и продажу абонементов любого типа в детских и учебных центрах, франшизах, танцевальных студиях, языковых и спортивных школах."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"subscriptions"}
        title='Создавайте абонементы'
        description='Наши инструменты позволят быстро провести продажу и переключить внимание администратора на клиента.'
        color='#03C4A4'
        btnDark={true} // Удалить после окончания акции бесплатное лето
        image={require("../../images/cover/subscriptions.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
      <AboutComponent
        title='Наглядность <br/> в деле'
        description='Интуитивно понятный интерфейс в программе учета посещений, записей и абонементов позволяет сотрудникам быстрее получать доступ к актуальным абонементам и быстро проводить сделки.'
        image={require("../../images/screenshot/subscription/screenshot_04@2x.png")}
        imageWidth={620}
        imageHeight={340}
        bgSize={"small"}
        reverse={true}
        color='#E65D49'/>
      <AboutComponent
        title='Исключение бумажных <br/> пропусков'
        description='CRM-система для детских центров позволяет перевести учет абонементов и посещений из офлайна в онлайн. Нет необходимости печатать бумажные абонементы и каждый раз проверять, зачеркнуто ли посещение в карточке. Статусы посещений отражаются в Личном кабинете клиента.'
        image={require("../../images/screenshot/subscription/screenshot_06@2x.png")}
        imageWidth={670}
        imageHeight={333}
        bgSize={"small"}
        color='#03c4a4'/>
      <Discount />
      <AboutComponent
        title='Гибкость <br/> настройки'
        description='Вы можете задать любой тип абонемента, будь то количество занятий или период действия. Вопрос как вести учет абонементов спортивной школы, секции или клуба полностью решает Параплан CRM.'
        image={require("../../images/screenshot/subscription/screenshot_03@2x.png")}
        imageWidth={670}
        imageHeight={427}
        reverse={true}
        color='#FFC800'/>
      <AboutComponent
        title='Готовые шаблоны'
        description='Заранее подготовьте шаблоны абонементов с конкретными настройками. Оформляйте нужный абонемент в один клик, без ручного повтора данных. Система учета абонементов добавит условия абонемента автоматически при выборе шаблона.'
        image={require("../../images/screenshot/subscription/screenshot_07@2x.png")}
        imageWidth={670}
        imageHeight={430}
        bgSize={"position-right"}
        color='#319DED'/>
      <BannerComponent author={"Галина Бусыгина, семейный центр «Единорожек»"}>
        Вы создали действительно качественный продукт, который понятен даже гуманитариям. Вы сложное устраиваете
        просто и грамотно решаете вопросы.
      </BannerComponent>
      <FeaturesGridComponent
        title={"Охватите все возможности"}
        subtitle={"Система Параплан обеспечит вас инструментами для быстрого роста бизнеса."}
        features={[
          {
            name: "Списание занятий",
            text: "Преподаватель отмечает ученика, а с абонемента автоматически списывается занятие.",
            image: require("../../images/icons/subscription_attendance.svg"),
          },
          {
            name: "Истекающие абонементы",
            text: "Главный экран покажет вам истекающие абонементы и напомнит оформить новый.",
            image: require("../../images/icons/subscription_expiring.svg"),
          },
          {
            name: "Пропущенные занятия",
            text: "Система позволяет сделать возврат средств за пропущенные занятия или использовать их для оплаты следующего абонемента.",
            image: require("../../images/icons/subscription_refund.svg"),
          },
          {
            name: "Оценка эффективности",
            text: "Система рассчитает, какой абонемент продается лучше всего, а вы сможете направить внимание отдела продаж на выгодные сделки.",
            image: require("../../images/icons/subscription_analytics.svg"),
          },
        ]}
      />
      <FeaturesComponent title="Дополнительные возможности" />
      <ContactFormComponent />
      <FeatureAccordion
            data={subscriptionsAccordionData}
            title='Как эффективно вести учет абонементов в CRM-системе для детских центров'
            descr='Случались ли в вашем центре подобные ситуации: клиент забыл бумажный пропуск и администратор не зачеркнул посещение; ученик потерял карточку с отметками посещений; администратор неразборчиво записал оплаченную сумму в бумажный журнал? Таких оплошностей, происходящих из-за человеческого фактора, можно избежать, если начать использовать CRM в детском, образовательном или спортивном клубе. CRM позволяет вести прозрачный учет абонементов и автоматизировать продажи. Оформление и редактирование абонементов происходит через CRM с помощью специальных встроенных инструментов. Сотрудники центра работают онлайн по привычному алгоритму, избегая бумажных носителей. CRM-система помогает ускорить процесс продажи и сосредоточиться на клиентском сервисе.'
      />
    </Layout>
  )
}

export default ClientsFeaturePage
